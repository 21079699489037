import { useEffect, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { animated, useSpring, useTransition } from "react-spring";
import EmbedController from "../../../../embed/EmbedController";
import { Line } from "../Line/Line";
import "./styles.scss";

export const DecalsSection = ({ decalsData, componentsState, title, itemsStateObj }) => {
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const meshEditingStatus = componentsState.activeObj.mesh_editing_status;

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });
  }, [meshEditingStatus]);

  // returns an array with animation style data on each item
  const animationOnItems = useTransition(decalsData, {
    trail: 20,
    initial: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    update: { enter: { opacity: 1, scale: 1 } },
    reset: true,
  });

  // prevents it from firing unless meshEditingStatus changes
  const dataToDisplayTransitionArray = useMemo(() => {
    return animationOnItems;
  }, [meshEditingStatus]);

  const handleClick = (decalId) => {
    const copy = { ...itemsStateObj.state.activeIds };

    let newActiveObjFront = {};
    let newActiveObjRear = {};

    if (meshEditingStatus === "both") {
      newActiveObjFront._id = decalId;
      // match decal and color for rear cover to the front cover
      newActiveObjRear = {
        color: itemsStateObj.state.activeIds[componentsState.activeId].front.color,
        engraving: { _id: decalId },
      };

      copy[componentsState.activeId].front.engraving = newActiveObjFront;
      copy[componentsState.activeId].rear = newActiveObjRear;
    } else if (meshEditingStatus === "front") {
      newActiveObjFront._id = decalId;
      copy[componentsState.activeId].front.engraving = newActiveObjFront;
    } else {
      newActiveObjRear._id = decalId;
      copy[componentsState.activeId].rear.engraving = newActiveObjRear;
    }

    itemsStateObj.setActiveId(copy);

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Decal Click - MGP",
        decalId: decalId,
        page: componentsState.activeId.split("_")[0],
      });
    }
  };

  const decalItems = dataToDisplayTransitionArray((style, decal, key, index) => {
    const activeEngravingId =
      componentsState.activeObj.mesh_editing_status === "rear"
        ? itemsStateObj.state.activeIds[componentsState.activeId].rear.engraving._id
        : itemsStateObj.state.activeIds[componentsState.activeId].front.engraving._id;
    const isDecalActive = decal._id === activeEngravingId && true;

    return (
      <Col key={decal._id} xs={3} className="p-0 decalCol">
        <animated.div style={style}>
          <div onClick={() => handleClick(decal._id)} className={`decalsContainer  m-1 d-flex flex-column ${isDecalActive && "isActive"}`}>
            <div className="d-flex justify-content-center align-items-center h-100">
              {/* { isDecalActive && <img className="checkmark" src="/images/checkmarkWhite.svg" alt="Checkmark" /> } */}
              <img className="py-1 h-100 w-100" src={decal.svg_src} alt="Decal" />
            </div>
            {/* {isDecalActive && decal.additional_price !== 0 && <p className="mb-0 px-2">{`$${decal.additional_price.toFixed(2)}`}</p>} */}
          </div>
        </animated.div>
      </Col>
    );
  });

  return (
    <div className="DecalsSection my-4">
      <animated.div style={animationProps}>
        <div className="d-flex flex-column mb-2 text-center">
          {title && <h6 className="mb-1">{title}</h6>}
          {title && <Line color="black" height="1px" width="30px" />}
        </div>
      </animated.div>
      <Row className="decalItems my-4">{decalItems}</Row>
      <div className="mt-3">
        <Line color="#AFAFAF" height="1px" width="85%" />
      </div>
    </div>
  );
};
